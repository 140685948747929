<template>
    <modal ref="modalDuplicarDescuentos" titulo="Duplicar los descuentos a otro cedis" :height="40" no-cancelar cerrar @guardar="duplicar">
        <cargando v-if="cargando" />
        <div class="row mx-0 justify-center">
            <div class="col-8 text-center text-general f-15 mb-2 ">
                Selecciona el cedis al cual se duplicará esta configuración
            </div>
            <div v-for="(cedis,d) in selectCedis" :key="d" class="col-8 px-4 mb-3 mt-2">
                <el-checkbox v-model="cedis.checked" class="check-dark text-general" :true-label="1" :false-label="0" :disabled="cedis.id === idCedis ? true : false">
                    {{ cedis.nombre }}
                </el-checkbox>
            </div>
        </div>
    </modal>
</template>

<script>
import Service from '~/services/configurar/admin/descuentosCliente';

export default {
    data(){
        return {
            cargando: false,
            cedisClonar: [],
			descuentos: [],
            idCedis: null,
            tipo: null,
			idCedisOrigen: null
        }
    },
	computed: {
		selectCedis() {
			return this.cedis_calculo.filter(o => o.id != 0 && o.id != this.idCedisOrigen)
		}
	},
    methods: {
        async toggle(descuentos, id){
            this.descuentos = descuentos
			this.idCedisOrigen = id
            this.$refs.modalDuplicarDescuentos.toggle();
        },

        async duplicar(){
			try {
				const payload = {
					idCedisDestino: this.selectCedis.filter(e => e.checked).map(e => e.id),
					descuentos: this.descuentos
				}
				const { data } = await Service.ClonarDescuentos(payload)
				if(data.data){
					this.notificacion('Éxito', data.mensaje, 'success')
					this.$refs.modalDuplicarDescuentos.toggle();
				}
			} catch (error) {
				return this.error_catch(error);
			}
        }
    }
}
</script>

<style>

</style>
