<template>
	<section>
		<div class="row mx-0 p-3 border-bottom">
			<div class="col-auto">
				<p class="f-18 text-general f-600">
					Descuentos de cupón
				</p>
			</div>
		</div>
		<div class="row mx-0 align-items-center pl-5 my-4 pb-3 border-bottom">
			<span class="text-general f-600">Cedis</span>
			<div class="col-auto pl-2">
				<el-select v-model="idCedis" size="small w-260px" @change="getDescuentosCedis">
					<el-option v-for="item in selectCedis" :key="item.id" :label="item.nombre" :value="item.id" />
				</el-select>
			</div>
			<el-tooltip effect="light" placement="bottom">
				<div slot="content">
					<p class="w-125px text-center">Duplicar configuración a otro cedis</p>
				</div>
				<div class="btn-general-2 cr-pointer d-middle-center border br-10 ml-auto mr-5"
					style="width:35px;height:35px;" @click="duplicateDataCoupon">
					<i class="icon-content-duplicate f-20 text-general" />
				</div>
			</el-tooltip>
		</div>
		<div v-if="idCedis" class="custom-scroll overflow-auto" style="height:calc(100vh - 288px)">
			<div class="px-5">
				<p class="f-15 text-general">Define los porcentajes de descuento que pueden tener los cupones creados por
					los vendedores de este cedis.</p>
				<ValidationObserver ref="validacionCreacion" v-slot="{ invalid }">
					<div class="row mx-0 my-2">
						<div class="col-auto px-2">
							<p class="pl-3 text-general f-12">Descuento (%)</p>
							<ValidationProvider v-slot="{ errors }" rules="required" name="descuento">
								<el-input v-model="descuento" class="h-36px w-205px" required />
								<p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
							</ValidationProvider>
						</div>
						<div class="col-auto mt-3 pt-1">
							<el-tooltip class="item" effect="light" content="Crear descuento" placement="bottom">
								<div class="bg-general cr-pointer d-middle-center br-8 wh-32"
									@click="handleAddDiscount(invalid)">
									<i class="icon-plus f-18 text-white" />
								</div>
							</el-tooltip>
						</div>
					</div>
				</ValidationObserver>
				<div class="list row">
					<div v-for="(item, index) in descuentos" :key="index" class="col-12 col-lg-5 my-3">
						<div class="row mx-0">
							<div class="col-auto px-2">
								<div class="d-middle h-36px w-205px br-8 px-3"
									style="background-color: #F6F9FB; border: 1px solid #DBDBDB;">
									{{ item.descuento }}%
								</div>
							</div>
							<div class="col-auto my-auto">
								<el-tooltip content="Eliminar" class="item" effect="light" placement="bottom">
									<div class="d-middle-center btn-action border wh-36"
										@click="handleDeleteDiscount(item.id, index)">
										<i class="icon-trash-empty f-20" />
									</div>
								</el-tooltip>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--  -->
		<modal-duplicar-descuento-cupones ref="refModalDuplicarDescuentoCupon" />
	</section>
</template>
<script>
import Service from '~/services/configurar/admin/descuentosCliente';
import modalDuplicarDescuentoCupones from '../partials/modalDuplicarDescuentoCupones.vue';

export default {
	components: { modalDuplicarDescuentoCupones },
	data() {
		return {
			cargandoCedis: false,
			cargandoClasi: false,
			datos: {},
			cedis: [],
			idCedis: null,
			descuento: null,
			descuentos: []
		}
	},
	computed: {
		selectCedis() {
			return this.cedis_calculo.filter(o => o.id != 0)
		}
	},
	watch: {
		selectCedis() {
			this.getDescuentosCedis();
		}
	},
	methods: {
		async getDescuentosCedis() {
			try {
				this.cargandoCedis = true;
				if (!this.idCedis) this.idCedis = this.selectCedis[0].id
				const { data } = await Service.GetDescuentos(this.idCedis)
				this.descuentos = data.data
			} catch (e) {
				this.error_catch(e);
			} finally {
				this.cargandoCedis = false;
			}
		},
		async handleAddDiscount() {
			try {
				if(!this.descuento) return
				if (this.descuento > 100) return this.notificacion('', 'El descuento no puede ser mayor a 100', 'warning')
				const payload = {
					descuento: this.descuento
				}
				const { data } = await Service.PostDescuentos(this.idCedis, payload);
				if(data.data) {
					this.notificacion('Éxito', data.mensaje, 'success')
					this.descuentos.push(data.data);
					this.descuento = null
					this.$refs.validacionCreacion.reset()
				}
			} catch (error) {
				return this.error_catch(error);
			}
		},
		async handleDeleteDiscount(id, idx){
			try {
				const { data } = await Service.DeleteDescuento(id);
				if(data.data){
					this.notificacion('Éxito', data.mensaje, 'success')
					this.descuentos.splice(idx, 1);
				}
			} catch (error) {
				return this.error_catch(error);
			}
		},
		duplicateDataCoupon() {
			const mapDescuentos = this.descuentos.map( (e) => e.descuento)
			this.$refs.refModalDuplicarDescuentoCupon.toggle(mapDescuentos, this.idCedis);
		}
	}
}
</script>