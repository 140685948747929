import axios from 'axios'

const API = 'cupones'

const ENDPOINTS = {
    GetDescuentos(idCedis){
        return axios.get(`${API}/cedis/${idCedis}/descuentos`)
    },
    PostDescuentos(idCedis, payload){
        return axios.post(`${API}/cedis/${idCedis}/descuentos`, payload)
    },
    ClonarDescuentos(payload){
        return axios.post(`${API}/cedis/descuentos/clonar`, payload)
    },
    DeleteDescuento(idDescuento){
        return axios.delete(`${API}/descuentos/${idDescuento}/delete`)
    }
};
export default ENDPOINTS;